.table_Machine {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    table {
    border: 2px solid black;
    width: 90%;
    height: auto;
    text-align: center;
    }
    
    th {
    border: 1px solid black;
    height: 50px;
    color: rgb(95, 95, 204);
}
    
    td {
    border: 1px solid black;
    }
    