@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;600&display=swap');                                              
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&family=Yanone+Kaffeesatz&display=swap');
.pdfDoc {
    display: flex;
    flex-direction: column;
    border: solid;
    box-sizing: border-box;
}

.pdf_part {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 35px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: solid;
    width: 95%;

}


header {
 font-family: 'Source Sans Pro', sans-serif;
 font-size: medium;
 display: flex;
 flex-direction: row;
 justify-content: space-between; 
 align-items:stretch;
 width: 100%;
}


header h2{
 font-size: medium;
 margin-left: 165px;
 margin-top: 0;
 margin-bottom: 0;
 padding-top: 0;
 padding-bottom: 0;
}

header h3,p{
    font-size: small;
    margin-left: 165px;
    overflow-wrap: break-word;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;  
}

header h3{
    font-size: medium;
}


  .logo_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    width: 100%;
  } 

  .mh_details{
    display: flex;
    flex-direction: column;
    padding-left: 220px;    
  
}



.iso_img{
    height: 20vmin;
    margin: 0;
    padding: 0;
}

.MH_Logo{
    margin-left: 150px;
    margin-bottom: 0;
    padding: 0;
    border: 1px dashed darkcyan;
    border-radius: 1px ;
}
.units_details{
    margin: 0;
    padding: 0;
}

h2,h3{
    font-size: 20px;
}

.qu_Prtydtls{
    margin-left:30px ;
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: space-between;
    margin-right:175px;
    padding-right: 20px;
}

.qu_ref_date{
    margin-top:30px ;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

}


.enquiry_details{
    margin: 40px;
}

.enquiry_details p{
    margin-left: 40px;
}

.signtr{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    margin-top: 10px;
    margin-left: 30px;
    padding: 15px;

}

.gst_dtls{
    margin-top: 30px;
    color: rgb(101, 199, 238);
    margin-left: 30px;
    padding: 15px;

}


.pdfBtn{
    margin-left: 10px;
}