.checkoutitemcontainer {
    width: 100%;
    display: flex;
    min-height: 100px;
    font-size: 20px;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid darkgrey;
    padding: 5px;
}

  .image-container {
    width: 20%;
    height: 100px;
  }
  
  .img-mchn {
    width: 200px;
    height: 100%;
  }

  .name,
    .quantity,
    .price ,
    .value,
    .edit-btn{
      width: 10%;
    }
  
    
  
    .remove-button ,.edit-btn{
      padding-left: 12px;
      cursor: pointer;
    }

    .arrow {
        cursor: pointer;
      }
  
     